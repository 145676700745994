/* eslint-disable import/no-anonymous-default-export */

type NodeJsEnv = Exclude<typeof process.env.NODE_ENV, 'test'>;

interface Config {
  mapbox: {
    public_token: string;
  }
}

const config: Record<NodeJsEnv, Config> = {
  development: {
    mapbox: {
      public_token: 'pk.eyJ1IjoicmVwdWVzdG9zb3RvbWF5b3JjYSIsImEiOiJja3ZyY2Z1cWQ0Zjg4MzBxZmVtYzZnMHI0In0.HEW2Vnrjewkrkb2NaxPyxw',
    },
  },
  production: {
    mapbox: {
      public_token: 'pk.eyJ1IjoicmVwdWVzdG9zb3RvbWF5b3JjYSIsImEiOiJja3Z4ZTVydTk5MDMxMm5xcG8zdXhsNG05In0.Da4TJJa8CtXexlX2D2o7gA',
    },
  }
}

const nodeEnv = process.env.NODE_ENV as NodeJsEnv;

export default config[nodeEnv];
