import * as React from "react"
import {
  Box,
  Grid,
} from "@chakra-ui/react"

import { FloatingWhatsApp } from 'react-floating-whatsapp-button'
import 'react-floating-whatsapp-button/dist/index.css'

import { Navbar } from '../ui/Navbar';
import { Footer } from '../ui/Footer';
import { Hero } from '../sections/Hero';
import { AboutUs } from '../sections/AboutUs';
import { Features } from '../sections/Features';
import { Location } from '../sections/Location';
import { Products } from '../sections/Products';
import { ContactUs } from '../sections/ContactUs';

const whatsAppWidgetAutoOpenTimeout = 9999999999;

export const LandingPageLayout: React.FunctionComponent = () => (
  <Box textAlign="center" fontSize="xl">
    <FloatingWhatsApp
      zIndex={100}
      phone="584124236129"
      popupMessage="Hola, ¿cómo podemos ayudarte?"
      headerTitle="Repuestos Sotomayor"
      size="50px"
      autoOpenTimeout={whatsAppWidgetAutoOpenTimeout}
    />
    <Grid minH="100vh" p={0} position="relative">
      <Hero />
      <Navbar />
      <AboutUs />
      <Features />
      <Location />
      <Products />
      <ContactUs />
      <Footer />
    </Grid>
  </Box>
)
