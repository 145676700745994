import { Parallax } from "react-parallax";
import {
  Box,
  Container,
  Image,
  Heading,
  Stack,
  Text,
  Button,
} from '@chakra-ui/react';
import logo from '../../images/repuestos-sotomayor-logo-transparent-trimmed.png';
import background from '../../images/kyle-grozelle-750350-unsplash.jpg';
import { links } from '../layouts/links';

// NOTE: Hardcoded the shit out of this but fuck it.
function scrollToFirstSection() {

  const navbarHeight = 64;

  const id = links[0].id;

  const element = document.getElementById(id);

  if (element) {

    const { top } = element.getBoundingClientRect();
    const yCoordinate = top + window.pageYOffset;

    // If top is less than the navbar's height it means the user
    // is already at that section. This avoid scrolling to the top.
    window.scrollTo({
      top: yCoordinate - navbarHeight,
      behavior: 'smooth'
    });
  }

}

export function Hero() {
  return (
    <Parallax bgImageStyle={{ objectFit: 'cover' }} bgImage={background} strength={-125}>
      <Container maxWidth="100%" id="hero" maxW={'5xl'} p={0} position="relative" >
        <Container >
          <Box
            position="absolute"
            top={0}
            left={0} 
            w="100%"
            h="100%"
            bg={'gray.900'}
            opacity={.66}
            zIndex={1}/>
          <Box
            w="100%"
            h="100%">

            <Stack
              textAlign={'center'}
              align={'center'}
              verticalAlign="center"
              justifyContent="center"
              spacing={{ base: 8, md: 10 }} minH="100vh" w="100%"
              py={{ base: 20, md: 28 }}>

              <Image
                zIndex={2}
                w={600}
                objectFit="cover"
                alt="Repuestos Sotomayor"
                src={logo}
              />

              <Heading
                zIndex={2}
                fontWeight={600}
                fontSize={{ base: '3xl', sm: '4xl', md: '5xl' }}
                lineHeight={'110%'}>
                <Text as={'span'} color={'gray.200'}>
                  La mayor calidad y{' '}
                </Text>
                <Text as={'span'} color={'brand.400'}>
                  trayectoria en repuestos
                </Text>
              </Heading>

              <Text
                zIndex={2}
                color={'gray.200'}
                maxW={'3xl'}>
                Venta de repuestos para carros, camionetas y camiones de marca Toyota, Chevrolet, Ford, Jeep, Isuzu, y Cummins.
              </Text>

              <Stack
                spacing={6}
                direction={'row'}>
                <Button
                  zIndex={2}
                  rounded={'full'}
                  px={6}
                  colorScheme={'brand'}
                  bg={'brand.400'}
                  textTransform="uppercase"
                  _hover={{ bg: 'brand.500' }}
                  onClick={scrollToFirstSection}
                >
                  más información
                </Button>

              </Stack>

            </Stack>

          </Box>
        </Container>
      </Container>
    </Parallax>
  );
}
