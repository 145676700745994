import {
  chakra,
  Box,
  Container,
  Link,
  Stack,
  Text,
  useColorModeValue,
  VisuallyHidden,
} from '@chakra-ui/react';
import { FaInstagramSquare, FaFacebookSquare } from 'react-icons/fa';
import { ReactNode } from 'react';

const SocialButton = ({
  children,
  label,
  href,
}: {
  children: ReactNode;
  label: string;
  href: string;
}) => {
  return (
    <Link isExternal href={href}>
      <chakra.button
        bg={'brand.400'}
        rounded={'full'}
        w={8}
        h={8}
        cursor={'pointer'}
        as={'span'}
        display={'inline-flex'}
        alignItems={'center'}
        justifyContent={'center'} 
        transition={'background 0.3s ease'}
        _hover={{
          bg: 'brand.900',
        }}>
        <VisuallyHidden>{label}</VisuallyHidden>
        {children}
      </chakra.button>
    </Link>
  );
};

export function Footer() {

  const now = new Date().getFullYear();

  return (
    <Box
      mt={12}
      pt={4}
      pb={4}
      bg={useColorModeValue('brand.50', 'gray.900')}
      color={useColorModeValue('gray.700', 'gray.200')}>

      <Container
        as={Stack}
        maxW={'6xl'}
        py={4}
        direction={{ base: 'column', md: 'row' }}
        spacing={4}
        justify={{ base: 'center', md: 'space-between' }}
        align={{ base: 'center', md: 'center' }}>

        <Text fontSize={'md'}>Copyright © Repuestos Sotomayor {now}</Text>

        <Stack direction={'row'} spacing={6}>
          <SocialButton label={'Facebook'} href="https://www.facebook.com/sotomayor.repuestos">
            <FaFacebookSquare fill='white' />
          </SocialButton>
          <SocialButton label={'Instagram'} href="https://www.instagram.com/sotomayor.repuestos/">
            <FaInstagramSquare fill='white' />
          </SocialButton>
        </Stack>

        <Text fontSize={'md'}>
          <Link isExternal href="https://www.robertmolina.dev/">
            <u>Página Web desarrollada por Robert Molina</u>
          </Link>
        </Text>

      </Container>

    </Box>
  );
}
