import { ReactElement } from 'react';
import { Container, Heading, SimpleGrid, Icon, Text, Stack, Flex, Box, Button, useColorModeValue } from '@chakra-ui/react';
import { IoMdPerson, IoIosStar, IoIosTime } from 'react-icons/io';

interface FeatureProps {
  title: string;
  text: string | React.ReactElement;
  icon: ReactElement;
}

const Feature = ({ title, text, icon }: FeatureProps) => {
  return (
    <Stack justifyContent="flex-start" alignItems="center">
      <Flex
        w={16}
        h={16}
        align={'center'}
        justify={'center'}
        rounded={'full'}
        color={'brand.400'}
        bg={useColorModeValue('brand.50', 'blue.900')}
        mb={1}>
        {icon}
      </Flex>
      <Text fontWeight={600}>{title}</Text>
      <Text color={useColorModeValue('gray.500', 'gray.400')}>{text}</Text>
    </Stack>
  );
};

export function Features() {
  const now = new Date().getHours();
  const isBetween8amAnd2pm = now >= 8 && now <= 14;

  return (
    <Container id="information" maxW={'5xl'} py={12}>

      <Stack spacing={12}>

        <Heading color="brand.400" alignSelf="center">Información</Heading>

        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
          <Feature
            icon={(
              <Icon
                as={IoMdPerson}
                w={10}
                h={10}
              />
            )}
            title={'¡Te lo tenemos!'}
            text={'Trabajamos con una amplia gama de repuestos para motores, trenes delanteros, transmisión, suspensión, y más!'}
          />
          <Feature
            icon={(
              <Icon
                as={IoIosStar}
                w={10}
                h={10}
              />
            )}
            title={'Calidad Garantizada'}
            text={'Ofrecemos las marcas de mejor calidad y más ecónomicas del mercado para su propio beneficio.'}
          />
          <Feature
            icon={(
              <Icon
                as={IoIosTime}
                w={10}
                h={10}
              />
            )}
            title={'Horarios'}
            text={(
              <>
                <span>Abiertos de Lunes a Sábado desde las <b>8:30 AM</b> hasta las <b>3:00 PM</b>.</span>
                <Box
                  display="block"
                  as={Button}
                  mt={4}
                  mr="auto"
                  ml="auto"
                  cursor="default"
                  pointerEvents="none"
                  variant="outline"
                  colorScheme={isBetween8amAnd2pm ? 'green' : 'red'}
                >
                  {isBetween8amAnd2pm ? 'Abiertos' : 'Cerrados'}
                </Box>
              </>
            )}
          />
        </SimpleGrid>

      </Stack>
    </Container>
  );
}
