import {
  Container,
  Flex,
  Heading,
  Text,
  useColorModeValue,
  Stack,
  SimpleGrid,
  Icon,
  Link,
} from '@chakra-ui/react';
import {
  IoIosMail,
  IoLogoWhatsapp,
} from 'react-icons/io';

export function ContactUs() {
  return (
    <Container id="contact-us" maxW={'5xl'} py={12} centerContent>

      <Stack spacing={4}>

        <Heading color="brand.400" alignSelf="center">Contáctanos</Heading>

        <Text color={useColorModeValue('gray.500', 'gray.400')} fontSize={'lg'}>
          Si necesitas saber sobre la disponibilidad o precio de alguno de nuestros productos, o alguna otra pregunta,
          ¡No dudes en contactarnos a través de los métodos a continuación!
        </Text>

        <br/>

        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>

          <Stack justifyContent="flex-start" alignItems="center">

            <Flex
              w={16}
              h={16}
              align={'center'}
              justify={'center'}
              rounded={'full'}
              color={'brand.400'}
              bg={useColorModeValue('brand.50', 'blue.900')}
              mb={1}>
              <Icon as={IoLogoWhatsapp} color={'brand.400'} w={10} h={10} />
            </Flex>

            <Stack marginX="auto" spacing={0} justifyContent="flex-start" alignItems="center" direction="column" maxW="80vw">
              <Link
                isExternal
                href={`whatsapp://send?phone=584124236129`}
              >
                <Text fontWeight={600} color="brand.400"><u>0412-423-6129</u></Text>
              </Link>
              <Link
                isExternal
                href={`whatsapp://send?phone=584144416287`}
              >
                <Text fontWeight={600} color="brand.400"><u>0414-441-6287</u></Text>
              </Link>
            </Stack>

          </Stack>

          <Stack marginX="auto" justifyContent="flex-start" alignItems="center" maxW="80vw">

            <Flex
              w={16}
              h={16}
              align={'center'}
              justify={'center'}
              rounded={'full'}
              color={'brand.400'}
              bg={useColorModeValue('brand.50', 'blue.900')}
              mb={1}>
              <Icon as={IoIosMail} color={'brand.400'} w={10} h={10} />
            </Flex>

            <Link
              isExternal
              href="mailto://repuestosotomayorca@gmail.com"
            >
              <Text fontWeight={600} color="brand.400"><u>repuestosotomayorca@gmail.com</u></Text>
            </Link>

          </Stack>

        </SimpleGrid>

      </Stack>

    </Container>
  );
}
