/* eslint-disable import/no-webpack-loader-syntax */
import 'mapbox-gl/dist/mapbox-gl.css';
import { Box, Stack, Heading, Container, Text, Link, Icon, Button, useTheme } from '@chakra-ui/react';
import ReactMapboxGl, { Marker, ZoomControl } from 'react-mapbox-gl';
import { IoMdPin, IoIosLink } from 'react-icons/io';
import config from '../../config';

/**
 * # Loading and transpiling the Web Worker separately
 * 
 * There's an error in production bundles that occurred while parsing the WebWorker bundle.
 * 
 * The following is a solution from the MapBox docs and GitHub:
 * 
 * - https://docs.mapbox.com/mapbox-gl-js/guides/install/#transpiling
 * - https://github.com/alex3165/react-mapbox-gl/issues/938#issuecomment-790802995
 */
import mapboxgl from 'mapbox-gl';
// @ts-ignore
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const { mapbox } = config;

const Map = ReactMapboxGl({
  accessToken: mapbox.public_token,
  doubleClickZoom: false,
  touchZoomRotate: false,
  scrollZoom: false
});

/**
 * Coordinates generated from Google Maps API:
 * https://www.google.com/maps/place/Repuestos+Sotomayor+C.A/@10.1726933,-68.0125119,19z/data=!4m8!1m2!2m1!1sAvenida+Padre+Alfonzo+Cruce+con+Avenida+Michelena.+Edif.+90-64.+Sector+la+Candelaria+Valencia,+Edo.+Carabobo.!3m4!1s0x8e806711fdb4f7f3:0xdf6f059983c57db2!8m2!3d10.1726422!4d-68.0121551
 */
const coordinates: [number, number] = [-68.01215378585046, 10.172681893720542];
const googleMapsCoordinatesLink = 'https://www.google.com/maps/place/Repuestos+Sotomayor+C.A/@10.1726475,-68.0126753,18.87z/data=!4m8!1m2!2m1!1sAvenida+Padre+Alfonzo+Cruce+con+Avenida+Michelena.+Edif.+90-64.+Sector+la+Candelaria+Valencia,+Edo.+Carabobo.!3m4!1s0x8e806711fdb4f7f3:0xdf6f059983c57db2!8m2!3d10.1726287!4d-68.0121554';
function openGoogleMapsWindow(): void {
  window.open(googleMapsCoordinatesLink, '_blank')?.focus();
}

export function Location() {

  const theme = useTheme();

  return (
    <>
      <Container id="location" maxW={'5xl'} py={12}>

        <Stack spacing={4}>
          <Heading color="brand.400" alignSelf="center">¿Dónde Estamos?</Heading>

          <Link
            alignSelf="center"
            isExternal
            fontSize={'lg'}
            fontWeight={600}
            as='button'
            onClick={openGoogleMapsWindow}
          >
            <Text color="blue.400">
              <i>
                <u>
                  Avenida Padre Alfonzo Cruce con Avenida Michelena. Edif. 90-64. Sector la Candelaria Valencia, Edo. Carabobo.
                </u>
              </i>
            </Text>
          </Link>

        </Stack>

      </Container>

      <Box
        position="relative"
        style={{ borderColor: theme.colors.brand[400] }}
        border="1px"
        borderRight="0px"
        borderLeft="0px"
      >

        <Map
          // eslint-disable-next-line react/style-prop-object
          style="mapbox://styles/mapbox/streets-v11"
          containerStyle={{
            height: '66vh',
            width: '100%'
          }}
          center={coordinates}
          zoom={[15]}
        >
          <ZoomControl
            style={{
              top: 5,
              right: 5
            }}
          />

          <Marker
            coordinates={coordinates}
            onClick={openGoogleMapsWindow}
            style={{ cursor: 'pointer' }}
          >
            <Icon
              as={IoMdPin}
              w={10}
              h={10}
              color="blue.400"
            />
          </Marker>

        </Map>

        <Button
          zIndex={5}
          position="absolute"
          top={1.5}
          left={1.5}
          margin="auto"
          colorScheme="brand"
          fontWeight="600"
          onClick={openGoogleMapsWindow}
        >
        <Icon
          as={IoIosLink}
          w={5}
          h={5}
          mr={2.5}
        />
          Abrir en Google Maps
        </Button>

      </Box>

    </>
  );

}
