import {
  Container,
  SimpleGrid,
  Image,
  Flex,
  Heading,
  Text,
  Stack,
  StackDivider,
  Icon,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  IoIosAperture,
  IoIosStar,
  IoIosCalendar,
} from 'react-icons/io';
import { ReactElement } from 'react';
import shopFront from '../../images/repuestos-sotomayor-shop-front.jpg';

interface FeatureProps {
  text: string;
  iconBg: string;
  icon?: ReactElement;
}

const Feature = ({ text, icon, iconBg }: FeatureProps) => {
  return (
    <Stack direction={'row'} align={'center'}>
      <Flex
        w={8}
        h={8}
        align={'center'}
        justify={'center'}
        rounded={'full'}
        bg={iconBg}>
        {icon}
      </Flex>
      <Text fontWeight={600}>{text}</Text>
    </Stack>
  );
};

export function AboutUs() {
  const yearsOfExperience = new Date().getFullYear() - 1991;

  return (
    <Container id="about-us" maxW={'5xl'} py={12}>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
        <Stack spacing={4}>
          <Text
            textTransform={'uppercase'}
            fontWeight={600}
            fontSize={'sm'}
            color={'brand.400'}
            bg={useColorModeValue('brand.50', 'blue.900')}
            p={2}
            alignSelf={'flex-start'}
            rounded={'md'}>
            ¿QUIÉNES SOMOS?
          </Text>
          <Heading>Repuestos Sotomayor</Heading>
          <Text color={useColorModeValue('gray.500', 'gray.400')} fontSize={'md'}>
            <b>Repuestos Sotomayor</b> es una empresa familiar fundada el <b>20 de enero de 1991</b>,
            con el objetivo de ser lideres y asesores en la venta de repuestos automotrices
            de calidad en <b>Venezuela</b>.
          </Text>
          <Text color={useColorModeValue('gray.500', 'gray.400')} fontSize={'md'}>
            Con dos generaciones y una experiencia de más de <b>{yearsOfExperience} años</b> en el mercado
            nacional de autopartes, mantenemos firme la promesa de brindar a nuestros
            clientes súper marcas, variedad y garantía, ya que contamos con un equipo
            de colaboradores fuertemente identificados con los valores de la empresa
            lo que hace de la meta en común sea brindar la <b>mejor calidad</b>, precios
            y atención del mercado.
          </Text>
          <br/>
          <Stack
            spacing={4}
            divider={
              <StackDivider
                borderColor={useColorModeValue('gray.100', 'gray.700')}
              />
            }
          >
            <Feature
              icon={
                <Icon as={IoIosStar} color={'brand.400'} w={5} h={5} />
              }
              iconBg={useColorModeValue('brand.100', 'blue.900')}
              text={'Mejor Calidad'}
            />
            <Feature
              icon={<Icon as={IoIosAperture} color={'brand.400'} w={5} h={5} />}
              iconBg={useColorModeValue('brand.100', 'blue.900')}
              text={`${yearsOfExperience} Años en el Mercado`}
            />
            <Feature
              icon={
                <Icon as={IoIosCalendar} color={'brand.400'} w={5} h={5} />
              }
              iconBg={useColorModeValue('brand.100', 'blue.900')}
              text={'Fundada en 1991'}
            />
          </Stack>
        </Stack>
        <Flex justifyContent="center">
          <Image
            rounded={'md'}
            alt={'shop front'}
            src={shopFront}
            objectFit={'cover'}
          />
        </Flex>
      </SimpleGrid>
    </Container>
  );
}
