import React, { useCallback, useEffect, useState } from 'react';
import useEmblaCarousel from 'embla-carousel-react'
import { Container, Heading, Text, Stack, Box, Image, useColorModeValue } from '@chakra-ui/react';
import Ticker from 'react-ticker';
import { EmblaNextButton, EmblaPrevButton } from '../ui/EmblaCarouselButtons';
import enginePartsCategoryImage from '../../images/catalog-partes-de-motor.png';
import frontTrainImage from '../../images/catalog-partes-de-tren-delantero.png';
import clutchKitImage from '../../images/catalog-kit-de-croche.png';
import breaksImage from '../../images/catalog-partes-de-frenos.png';
import bearingsImage from '../../images/catalog-rodamientos.png';
import gasPumpImage from '../../images/catalog-bomba-y-pila-de-gasolina.png';
import wheelHubImage from '../../images/catalog-cubo-de-rueda-o-mozo.png';
import lubricantsImage from '../../images/catalog-lubricantes.png';
import transmissionImage from '../../images/catalog-partes-de-transmision.png';
import fordLogo from '../../images/ford-logo.png';
import jeepLogo from '../../images/jeep-logo.png';
import chevroletLogo from '../../images/chevrolet-logo.png';
import toyotaLogo from '../../images/toyota-logo.png';
import isuzuLogo from '../../images/isuzu-logo.png';
import cumminsLogo from '../../images/cummins-logo.png';

interface Category {
  name: string;
  image: string;
}

const catalog: Category[] = [
  {
    name: 'Partes de motor',
    image: enginePartsCategoryImage,
  },
  {
    name: 'Partes de tren delantero',
    image: frontTrainImage,
  },
  {
    name: 'Kit de croche',
    image: clutchKitImage,
  },
  {
    name: 'Partes de frenos',
    image: breaksImage,
  },
  {
    name: 'Rodamientos',
    image: bearingsImage,
  },
  {
    name: 'Bomba y pila de gasolina',
    image: gasPumpImage,
  },
  {
    name: 'Cubo de rueda o mozo',
    image: wheelHubImage,
  },
  {
    name: 'Lubricantes',
    image: lubricantsImage,
  },
  {
    name: 'Partes de transmisión',
    image: transmissionImage,
  },
];

function Marquee({ width, padding } : { width: number, padding: number }) {

  const logos = [
    fordLogo,
    jeepLogo,
    chevroletLogo,
    toyotaLogo,
    isuzuLogo,
    cumminsLogo,
  ];

  return (

    <Stack
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      direction="row"
      w={(width + (padding * 2)) * logos.length}
    >

      {
        logos.map((src, index) => (
          <Image
            key={index}
            // maxW="25vw"
            // minW="250px"
            w={width}
            p={padding}
            src={src}
            objectFit='contain'
          />
        ))
      }

    </Stack>

  );

}

export function Products() {

  const [viewportRef, embla] = useEmblaCarousel({
    align: 'center',
    skipSnaps: false,
    loop: true,
    draggable: false,
  });
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);
  const onSelect = useCallback(() => {
    if (!embla) return;
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla]);

  useEffect(() => {
    if (!embla) return;
    embla.on("select", onSelect);
    onSelect();
  }, [embla, onSelect]);

  // Marquee settings:
  const boxHeight = 300;
  const imageWidth = 300;
  const stackPadding = 8;

  return (
    <>
      <Container id="products" maxW={'5xl'} py={12}>
        <Stack spacing={4}>

          <Heading color="brand.400" alignSelf="center">Nuestro Catálogo</Heading>

          <Text color={useColorModeValue('gray.500', 'gray.400')} fontSize={'lg'}>
            En <b>Repuestos Sotomayor</b> nuestro objetivo es disminuir las complicaciones
            por los repuestos, es por eso que solo ofrecemos autopartes originales
            y de marcas reconocidas por su excelente calidad y duración.
            Brindando la seguridad de sólo adquirir lo mejor del mercado
            para los vehículos.
          </Text>
        </Stack>

      </Container>

      <Box className="embla">
        <Box className="embla__viewport" ref={viewportRef}>
          <Box className="embla__container">
            {catalog.map(i => (
              <Box className="embla__slide" key={i.name}>
                <Box className="embla__slide__inner">
                  <Image
                    className="embla__slide__Image"
                    src={i.image}
                    alt={i.name}
                    width="100%"
                    objectFit="contain"
                  />
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
        <EmblaPrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
        <EmblaNextButton onClick={scrollNext} enabled={nextBtnEnabled} />
      </Box>

      <Container maxW={'5xl'} py={12}>

        <Text color={useColorModeValue('gray.500', 'gray.400')} fontSize={'lg'}>
          Ofrecemos autopartes originales
          y de marcas reconocidas por su excelente calidad y duración
          para las siguientes marcas:
        </Text>

      </Container>

      <Box
        bgGradient={useColorModeValue(
          'linear(to-r, white 0%, brand.50 20%, brand.50 80%, white 100% )',
          'linear(to-r, rgb(33,33,33) 0%, gray.900 5%, gray.900 95%, rgb(33,33,33) 100% )',
        )}
        css={{
          '.ticker': {
            marginTop: 24,
            marginBottom: 24,
          },
        }}
      >
        <Ticker speed={5} direction="toLeft" height={boxHeight}>
          {() => <Marquee width={imageWidth} padding={stackPadding} />}
        </Ticker>
      </Box>

    </>
  );

};

// const enginePartsCategory: Category = {
//   name: 'Partes de Motor',
//   image: '',
//   // products: [
//   //   {
//   //     name: 'Pistones',
//   //     image: '',
//   //   },
//   //   {
//   //     name: 'Anillos',
//   //     image: '',
//   //   },
//   //   {
//   //     name: 'Conchas de biela',
//   //     image: '',
//   //   },
//   //   {
//   //     name: 'Conchas de bancada',
//   //     image: '',
//   //   },
//   //   {
//   //     name: 'Juego de empacaduras',
//   //     image: '',
//   //   },
//   //   {
//   //     name: 'Kits de tiempo Bombas de agua',
//   //     image: '',
//   //   },
//   //   {
//   //     name: 'Bombas de aceite',
//   //     image: '',
//   //   },
//   //   {
//   //     name: 'Taquetes',
//   //     image: '',
//   //   },
//   //   {
//   //     name: 'Camisas',
//   //     image: '',
//   //   },
//   //   {
//   //     name: 'Liner kits',
//   //     image: '',
//   //   },
//   // ],
// };

// const frontTrain: Category = {
//   name: 'Tren Delantero',
//   image: '',
//   // products: [
//   //   {
//   //     name: 'Muñones',
//   //     image: '',
//   //   },
//   //   {
//   //     name: 'Terminales ',
//   //     image: '',
//   //   },
//   //   {
//   //     name: 'Rótulas',
//   //     image: '',
//   //   },
//   //   {
//   //     name: 'Mesetas',
//   //     image: '',
//   //   },
//   //   {
//   //     name: 'Pasadores de Dirección',
//   //     image: '',
//   //   },
//   //   {
//   //     name: 'Barras',
//   //     image: '',
//   //   },
//   //   {
//   //     name: 'Lápices de barra estabilizadora',
//   //     image: '',
//   //   },
//   // ],
// };

// const clutchKit: Category = {
//   name: 'Kits de croche',
//   image: '',
//   // products: [
//   //   {
//   //     name: 'Platos de presión',
//   //     image: '',
//   //   },
//   //   {
//   //     name: 'Discos de croche',
//   //     image: '',
//   //   },
//   //   {
//   //     name: 'Collarínes',
//   //     image: '',
//   //   },
//   //   {
//   //     name: 'Bombines de croche',
//   //     image: '',
//   //   },
//   // ],
// };

// const breaks: Category = {
//   name: 'Freno',
//   image: '',
//   // products: [
//   //   {
//   //     name: 'Pastillas de freno',
//   //     image: '',
//   //   },
//   // ],
// };

// const suspension: Category = {
//   name: 'Suspensión',
//   image: '',
//   // products: [
//   //   {
//   //     name: 'Amortiguadores',
//   //     image: '',
//   //   },
//   //   {
//   //     name: 'Resortes de Ballestas',
//   //     image: '',
//   //   },
//   //   {
//   //     name: 'Espirales',
//   //     image: '',
//   //   },
//   // ],
// };

// const bearings: Category = {
//   name: 'Rodamientos',
//   image: '',
//   // products: [
//   //   {
//   //     name: 'Cubos de rueda',
//   //     image: '',
//   //   },
//   //   {
//   //     name: 'Rodamientos en general',
//   //     image: '',
//   //   },
//   // ],
// };
