// Libraries
import React, { useState, useRef, useEffect, useCallback, ReactNode } from 'react';
import {
  Box,
  Flex,
  Link,
  useColorModeValue,
  Stack,
  LinkProps,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import { links } from '../layouts/links';

const observerConfig = { root: null, threshold: [0, 0.25, 0.75, 1] };
const scrollConfig = { capture: true, passive: true };

let lastScrollTop = window.pageYOffset;

interface NavLinkProps extends LinkProps {
  children?: ReactNode;
  to?: string;
  isActive?: boolean;
}

const NavLink: React.FunctionComponent<NavLinkProps> = ({ children, to, isActive, ...props }) =>  {

  const activeProps: LinkProps = isActive
    ? {
      color: 'brand.400',
      _before: {
        transition: 'all 300ms ease 0s',
        content: "\"\"",
        position: 'absolute',
        background: 'brand.400',
        bottom: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        display: 'block',

        width: '100%',
        height: .5,
        opacity: 1,
      },
    }
    : {
      transition: 'all 300ms ease 0s',
      _before: {
        transition: 'all 300ms ease 0s',
        content: "\"\"",
        position: 'absolute',
        background: 'brand.400',
        bottom: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        display: 'block',

        width: 0,
        height: .5,
        opacity: 0,
      }
    };

  return (
    <Link
      {...props}
      display="flex"
      alignItems="center"
      justifyContent="center"
      position="relative"
      px={4}
      py={1}
      _hover={{
        textDecoration: 'none',
        bg: useColorModeValue('gray.200', 'gray.700'),
      }}
      fontSize="16px"
      href={to}
      {...activeProps}
    >
      {children}
    </Link>
  );

};

export function Navbar() {
  const navbarRef = useRef<HTMLDivElement | null>(null);
  const elementsInView = useRef<Record<string, IntersectionObserverEntry>>({});
  const [activeNavLink, setActiveNavLink] = useState<HTMLElement | {}>({});

  const scrollToElementOnClick = (id: string) => {

    const element = document.getElementById(id);

    if (element && navbarRef.current) {

      const { top } = element.getBoundingClientRect();
      const yCoordinate = top + window.pageYOffset;

      // If top is less than the navbar's height it means the user
      // is already at that section. This avoid scrolling to the top.
      window.scrollTo({
        top: yCoordinate - navbarRef.current.clientHeight,
        behavior: 'smooth'
      });
    }

  };

  const onIntersection: IntersectionObserverCallback = useCallback((entries = []) => {

    const inView = { ...elementsInView.current };

    entries.forEach(entry => {

      switch (true) {

        // If intersecting, add to the elements in view.
        case entry.isIntersecting:
          inView[entry.target.id] = entry;
          break;
        // If not intersecting, remove the entry from the elements in view.

        case !entry.isIntersecting:
          // inView[entry.target.id] = undefined;
          delete inView[entry.target.id];
          break;

        default: // Do nothing

      }

    });

    elementsInView.current = inView;

  }, []);

  const setActiveElementOnScroll = () => {

    // scrollDirection will access the RECT object properties based on the direction
    // that the user is scrolling to determine the active element in the Navbar,
    const newScrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollDirection = newScrollTop >= lastScrollTop ? 'bottom' : 'top';

    // Horizontal scroll of the Navbar when it overflows.
    if (navbarRef.current && newScrollTop !== lastScrollTop) {

      const topAnchorEl = document.getElementById(links[0].id);
      if (!topAnchorEl) return;

      const { offsetTop } = topAnchorEl;
      const totalEffectiveHeight = document.body.clientHeight - window.innerHeight - offsetTop;
      const currentHeight = window.pageYOffset - offsetTop;
      const percent = (currentHeight) / (totalEffectiveHeight);
      const newNavbarScrollLeft = (navbarRef.current.scrollWidth - navbarRef.current.clientWidth) * percent;

      switch (scrollDirection) {

        case 'bottom':
          if (newNavbarScrollLeft > navbarRef.current.scrollLeft) navbarRef.current.scrollLeft = newNavbarScrollLeft;
          break;

        case 'top':
          if (newNavbarScrollLeft < navbarRef.current.scrollLeft) navbarRef.current.scrollLeft = newNavbarScrollLeft;
          break;

        default: // Do nothing.

      }

    }

    let inViewElements = Object.values(elementsInView.current);

    const maxValueOfIntersectionRatios = Math.max(...inViewElements.map(entry => (entry ? entry.intersectionRatio : 0)), 0);

    inViewElements = inViewElements.filter(entry => entry && (
      entry.intersectionRatio === maxValueOfIntersectionRatios
    ));

    // Choosing the active navbar element:
    // If the array of inView elements is 1, set the only visible element.
    if (inViewElements.length === 1) setActiveNavLink(inViewElements[0].target);
    else if (scrollDirection) {

      const activeElement = inViewElements.reduce((prev, current) => {

        if (!prev) return current;

        const prevTargetRect = prev.target.getBoundingClientRect()[scrollDirection];
        const currentTargetRect = current.target.getBoundingClientRect()[scrollDirection];

        // If scrolling to the top, select the element closest to the top.
        if (scrollDirection === 'top') {
          return (
            (prevTargetRect <= currentTargetRect) ?
              prev :
              current
          );
        }

        // Otherwise, select the element closest to the bottom.
        return (
          (prevTargetRect >= currentTargetRect) ?
            prev :
            current
        );

      }, null as IntersectionObserverEntry | null);

      if (activeElement) setActiveNavLink(activeElement.target);

    }

    lastScrollTop = newScrollTop;

  };

  useEffect(() => {

    const observedElements = links.map(({ id }) => document.getElementById(id));
    const observer = new IntersectionObserver(onIntersection, observerConfig);
    observedElements.forEach(element => element && observer.observe(element));

    // Unobserve when unmouting.
    return () => {
      observedElements.forEach(element => element && observer.unobserve(element));
    };

  }, [onIntersection]);

  useEffect(() => {

    window.addEventListener('scroll', setActiveElementOnScroll, scrollConfig);

    return () => {
      window.removeEventListener('scroll', setActiveElementOnScroll, scrollConfig);
    };

  }, []);

  return (

    <Box
      zIndex={20}
      ref={navbarRef}
      bg={useColorModeValue('gray.100', 'gray.900')}
      boxShadow="md"
      p={0}
      position="sticky"
      top={0}
      width="100%"
      overflowX="scroll"
      overflowy="hidden"
      css={{
        '&::-webkit-scrollbar': {
          width: '0px !important',
          height: '0px !important',
        },
      }}
    >
      <Flex
        h={16}
        alignItems={'center'}
        justifyContent={'space-between'}
      >

        <Flex
          flex={1}
          display={{ 'base': 'none', 'md': 'flex' }}
          fontFamily="Kaushan Script"
          color={'brand.400'}
          fontWeight={600}
          justifyContent="flex-start"
          cursor="pointer"
          onClick={() => scrollToElementOnClick('hero')}
          px={4}
        >
          Repuestos Sotomayor
        </Flex>

        <Flex
          flex={1}
          alignItems={'center'}
          justifyContent="center"
          h="100%"
        >
          <Stack
            h="100%"
            direction={'row'}
            spacing={0}>
            {links.map(l => (
              <NavLink
                key={l.id}
                isActive={(activeNavLink as HTMLElement).id === l.id}
                onClick={() => scrollToElementOnClick(l.id)}
              >
                {l.text}
              </NavLink>
            ))}
          </Stack>
        </Flex>

        <Flex
          flex={1}
          justifyContent="flex-end"
          px={4}
        >
          <ColorModeSwitcher />
        </Flex>
      </Flex>
    </Box>

  );
}
