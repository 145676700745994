import * as React from "react"
import { ChakraProvider } from "@chakra-ui/react"
import { theme } from './theme';

type GlobalProviderProps = React.PropsWithChildren<{}>;

export const GlobalProvider: React.FunctionComponent<GlobalProviderProps> = (props) => (
  <ChakraProvider theme={theme}>
    {props.children}
  </ChakraProvider>
)
